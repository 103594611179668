import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Home from "./Views/Home/Home";
import SubHome from "./Views/SubHome/SubHome";
import Desktop from "./Views/Test/Desktop";

import Magazine from "./Views/Magazine/Magazine";

function App() {
  const location = useLocation();

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/desktop" element={<Desktop />} />
        <Route path="/Celandine-The-Sunlit-Bloom" element={<Magazine />} />
      </Routes>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
