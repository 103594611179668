import FrameComponent from "./FrameComponent";
import FrameComponent1 from "./FrameComponent1";
import FrameComponent2 from "./FrameComponent2";
import FrameComponent3 from "./FrameComponent2";
import FrameComponent4 from "./FrameComponent3";
import PropTypes from "prop-types";
import "./style.css";
import Header from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Image from "../../Assets/Clentaine/IMG_0473 1.png";
import Image1 from "../../Assets/Clentaine/image.png";
import Image2 from "../../Assets/Clentaine/DSC08343-4-HDR 1.png";
import Image3 from "../../Assets/Clentaine/IMG_0491 1.png";
import Image4 from "../../Assets/Clentaine/DSC08460-4-HDR 1.png";
import Image5 from "../../Assets/Clentaine/DSC08556-4-HDR 1.png";
import Image6 from "../../Assets/Clentaine/DSC08414 1.png";
import Image7 from "../../Assets/Clentaine/DSC08174-5-HDR 1.png";
import Image8 from "../../Assets/Clentaine/IMG_0501 1.png";
import Image9 from "../../Assets/Clentaine/IMG_0496 1 (1).png";
import Image10 from "../../Assets/Clentaine/DSC08033-2-HDR 1.png";
import Image11 from "../../Assets/Clentaine/IMG_0487 1.png";
import Image12 from "../../Assets/Clentaine/12.png";
import Image13 from "../../Assets/Clentaine/IMG_0471 2.png";
import Image15 from "../../Assets/Clentaine/271568_0_20240823125550 copy 2 1.png";
import Image16 from "../../Assets/Clentaine/Group 6 1 (2).png";
import Image22 from "../../Assets/IMG_0501 1.png";

import Image19 from "../../Assets/DSC08343-4-HDR 1.png";
import Image20 from "../../Assets/IMG_0491 1.png";
import Image21 from "../../Assets/DSC08556-4-HDR 1.png";

import Image18 from "../../Assets/Group 6 1 (1).png";

import Image17 from "../../Assets/Clentaine/IMG_0513 1 (1).png";
import Image14 from "../../Assets/Clentaine/WEB IMG (1).png";

import { BookOpen, Play } from "lucide-react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import videoFile from "../../Assets/Clentaine/FOR WEB.mp4"; // Updated video import

const Desktop = ({ className = "" }) => {
  const videoRef = useRef(null);
  const history = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div className={`desktop-3 ${className}`}>
        <section className="desktop-3-inner">
          <div className="frame-parent">
            <FrameComponent1 />
          </div>
        </section>
        <FrameComponent3 />
        <section className="desktop-3-child">
          <div className="rectangle-parent">
            <div className="frame-child" />
            <div className="at-the-core">
              The clients, a family of four returning from Dubai, sought a
              design that would blend their memories of Kerala with the modern
              amenities they were accustomed to. Merging the parent's dream of
              an idyllic tropical bliss with the children's need for
              contemporary spaces was the challenge.
              <br />
              <br />
              Although the clients were deeply involved in the design process
              through online discussions, they did not view the construction in
              person until its completion, showing their confidence and close
              relationship with the design team.
            </div>
          </div>
        </section>
        <div className="spatial">
          <h1 className="spatial-layout">Spatial Layout</h1>
          <img className="img-0492-4-ico" loading="lazy" alt="" src={Image} />
        </div>

        <section className="frame-section">
          <div className="desktop-3-4"></div>
          <div className="frame-group">
            <div className="rectangle-container">
              <div className="frame-inner" />
              <div className="the-gazebo-is">
                At the core of Celandine House grows a lush green courtyard,
                which functions as its "breathing heart" physically and
                conceptually. The living room and its double-height ceilings,
                open into this eastern courtyard, evoking the sense of immersion
                in Nature. Such seamless integration of the inside-outside
                spaces blurs the boundaries between the natural and built
                environments.
              </div>
            </div>

            <div className="div">
              <div className="rectangle-containers">
                <div className="frame-inner" />
                <div className="the-gazebo-is">
                  The orientation of the two elevated faces of the house is
                  towards the southern and western directions to maximize
                  visibility from the highway. The long linking corridors
                  provide circulation and guide the transition from public to
                  semi-public to private spaces.
                </div>
              </div>
              <img className="image-12" src={Image} />
            </div>
          </div>
          <div>
            <img className="images-22" src={Image1} />
          </div>
          <div className="spatial-div">
            <div>
              <img src={Image2} style={{ zIndex: 2 }} />
            </div>
            <div>
              <img src={Image3} style={{ zIndex: 2, position: "relative" }} />
            </div>
            <div>
              <div>
                <div className="rectangle-container1">
                  <div className="frame-inner" />
                  <div className="the-gazebo-is">
                    The private bedrooms are designed with personal balconies or
                    bay windows so that each family member may enjoy the
                    outdoors from the shade and at their own pace. These spaces
                    also include walk-in wardrobes and en-suite bathrooms,
                    ensuring privacy and comfort.
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={Image4}
                  style={{ position: "relative", top: "-82px" }}
                />
              </div>
            </div>
          </div>
          <div className="spatial-div2">
            <div>
              <img src={Image6} style={{ position: "relative", zIndex: 1 }} />
            </div>
            <div>
              <img src={Image5} style={{ position: "relative", zIndex: 1 }} />
            </div>
          </div>
          <div className="rectangle-container-2">
            <div className="frame-inner" />
            <div className="the-gazebo-is">
              The Gazebo is designed as an open landscape element with a steel
              structure, which can be accessed through the living room
              courtyard. Encircled by thick greenery it feels like a Landscaped
              Island nestled within dense foliage.
            </div>
          </div>
          <div className="frame-container">
            <div className="functionality-materiality-wrapper">
              <h1 className="functionality-materiality">{`Functionality & Materiality`}</h1>
            </div>
            <div className="rectangle-parent1">
              <div className="rectangle-div" />
              <div className="orientation-of-the">
                Every design choice for this residence was deliberately done to
                weave materiality and functionality into its aesthetics.
                Strategic ventilation techniques such as openable louvers and
                jalis ensure a constant flow of fresh air throughout the home.
                The skylight over the courtyard lets in ambient sunlight while
                additional light filters in through the glass blocks on the
                eastern side. This natural illumination bathes the interior in a
                warm, inviting glow. The striking steel and wood staircase
                rivals any art installation while still providing vertical
                circulation.
              </div>
              <img
                className="img-0492-11-icon"
                loading="lazy"
                alt=""
                src={Image7}
              />
            </div>
          </div>
          <div className="div-3">
            <div className="image-div-1">
              <img src={Image8} />
            </div>
            <div className="rectangle-container-4">
              <div className="frame-inner" />
              <div className="the-gazebo-is">
                Perforated steel screens are strategically used in several key
                areas to control lighting. In the bedroom, these screens are
                installed on the western windows to filter the harsh rays of the
                setting sun. Such usage reduces heat and adds a dynamic visual
                element to the space while maintaining privacy.
                <br />
                <br />
                Roofing comprises stratified layers in natural tones to ground
                the building to its surroundings. The sandwich panel roofing
                with its exposed concrete ceiling is evident over the central
                courtyard as it creates a visual contrast against the greenery
                below. <br />
                <br />
                Polished concrete flooring runs throughout the house from the
                living room to the bedrooms. Exposed structural elements such as
                steel beams provide an industrial edge, whereas natural wooden
                textures soften the overall aesthetic.
                <br />
                <br />
                The exterior has a combination of matte and glossy concrete
                finishes juxtaposing the glass and wooden elements to give the
                facade a modern yet organic feel. The matte concrete is used on
                the subdued exterior walls while the glossy concrete highlights
                key features such as the sleek frames around large windows.
                Expansive glass panels and floor-to-ceiling windows allow
                natural light to flood in while offering stunning views of the
                courtyard and landscapes. The main entrance door and other
                wooden elements introduce warmth and organic touch to the
                minimalist exterior.
                <br />
              </div>
            </div>
          </div>
          <div className="div-4">
            <div>
              <img className="image9" src={Image9} />
            </div>
            <div>
              <img className="image10" src={Image10} />
            </div>
          </div>
          <div>
            <div className="div-6">
              <p>
                The color palette is dominated by earth tones, with living room
                walls painted a warm, muted beige as a backdrop for the bold
                yellow accents inspired by the celandine flower. Soft shades of
                brown and green in the bedrooms create a calm and restive
                atmosphere. The natural wooden cabinets in the kitchen feature
                subtle pops of yellow to reinforce the cohesive visual
                experience.
              </p>
            </div>
            <div>
              <img className="image11" src={Image11} />
            </div>
          </div>
        </section>
        <div className="div-7">
          <h2>Conclusion</h2>
          Celandine Residence is a seamless integration of modern design ethos
          with the tropical essence of Kerala. The outcome is a serene living
          experience that not only meets the functional needs of the occupants
          but also fosters a deep connection with nature. By honoring tradition
          while embracing innovation this abode is a sanctuary and a statement
          of contemporary tropical architecture.
        </div>
        <div>
          <img className="image12" src={Image12} />
        </div>
      </div>
      <div className="mobile-div">
        <div className="div-mobile-1">
          <div className="mobile-main">
            <div className="mobile-head">
              Celandine{" "}
              <div className="mobile-btn">
                <button
                  className="watch-video-btn"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/embed/PsTpnmwGE14",
                      "_blank"
                    )
                  }
                >
                  <Play size={20} /> Watch Video
                </button>
                <button
                  onClick={() => {
                    history("/Celandine-The-Sunlit-Bloom");
                  }}
                  className="e-magazine-btn"
                >
                  <BookOpen size={20} /> E-Magazine
                </button>
              </div>
            </div>
            <div className="mobile-para">
              <p>
                This sunlit 'breathing' house ensures that its occupants are
                immersed in Nature; engaging with her whims, her sunny warmth
                and playful rains.
              </p>
            </div>
          </div>
        </div>
        <div className="mobile-main-div">
          <div className="mobile-div2">Site Context</div>
          <div className="mobile-div3">
            <img className="image12" src={Image15} />
            <p style={{ textAlign: "justify" }}>
              The 15-cent site in Muringoor, Thrissur, Keralam with a busy
              national highway just 20 yards away, directed the focus on
              creating a tranquil urban retreat. An inward-facing orientation
              highlights the lush green courtyard, serving as the nucleus of the
              3500 sq ft house. Kerala’s tropical climate, with its heavy
              monsoons, verdant terrains, and hot, humid summers, demands
              architecture that is adaptable and attuned to this context.
            </p>
            <img className="image12" src={Image14} />
            <p style={{ marginTop: "5px" }} className="principal-architect">
              Principal Architect: Ar.Shyam Raj.C
            </p>
            <p className="project-associate-aramal">
              Project Associate: Ar.Amal Raj.C
            </p>
            <p className="project-associate-aramal">
              location details: Muringoor, Thrissur
            </p>
            <p className="project-associate-aramal">plot area: 15 cents</p>
            <p className="project-associate-aramal">project sq ft: 3500 sqft</p>
            <p className="project-associate-aramal">{`Client Name Details: `}</p>
            <p className="project-associate-aramal">{`Mr.Jickson & Mrs.Jisha`}</p>
            <p className="project-associate-aramal">Firm name:</p>
            <p className="project-associate-aramal">
              7th HUE ARCHITECTURE COLLECTIVE
            </p>
            <div className="mobile-div23">Planning</div>
            <img style={{ width: "340px" }} src={Image18} />
            <p style={{ textAlign: "justify" }}>
              The clients, a family of four returning from Dubai, sought a
              design that would blend their memories of Kerala with the modern
              amenities they were accustomed to. Merging the parent's dream of
              an idyllic tropical bliss with the children's need for
              contemporary spaces was the challenge
            </p>
            <img style={{ width: "100%" }} src={Image17} />
            <p style={{ textAlign: "justify" }}>
              The clients, a family of four returning from Dubai, sought a
              design that would blend their memories of Kerala with the modern
              amenities they were accustomed to. Merging the parent's dream of
              an idyllic tropical bliss with the children's need for
              contemporary spaces was the challenge.
              <br />
              <br />
              Although the clients were deeply involved in the design process
              through online discussions, they did not view the construction in
              person until its completion, showing their confidence and close
              relationship with the design team.
            </p>
            <div className="mobile-div23">Spatial Layout</div>
            <img style={{ width: "100%" }} src={Image} />
            <p style={{ textAlign: "justify" }} className="the-gazebo-is">
              At the core of Celandine House grows a lush green courtyard, which
              functions as its "breathing heart" physically and conceptually.
              The living room and its double-height ceilings, open into this
              eastern courtyard, evoking the sense of immersion in Nature. Such
              seamless integration of the inside-outside spaces blurs the
              boundaries between the natural and built environments.
            </p>
            <img style={{ width: "100%" }} src={Image1} />
            <p style={{ textAlign: "justify" }}>
              The orientation of the two elevated faces of the house is towards
              the southern and western directions to maximize visibility from
              the highway. The long linking corridors provide circulation and
              guide the transition from public to semi-public to private spaces.
            </p>
            <img src={Image19} style={{ width: "100%" }} />
            <p style={{ textAlign: "justify" }}>
              The private bedrooms are designed with personal balconies or bay
              windows so that each family member may enjoy the outdoors from the
              shade and at their own pace. These spaces also include walk-in
              wardrobes and en-suite bathrooms, ensuring privacy and comfort.
            </p>
            <img src={Image20} style={{ width: "100%" }} />
            <p style={{ textAlign: "justify" }}>
              The Gazebo is designed as an open landscape element with a steel
              structure, which can be accessed through the living room
              courtyard. Encircled by thick greenery it feels like a Landscaped
              Island nestled within dense foliage.
            </p>
            <img src={Image21} style={{ width: "100%" }} />
            <div className="mobile-div23">Functionality & Materiality</div>
            <img style={{ width: "100%" }} src={Image7} />
            <p style={{ textAlign: "justify" }}>
              Every design choice for this residence was deliberately done to
              weave materiality and functionality into its aesthetics. Strategic
              ventilation techniques such as openable louvers and jalis ensure a
              constant flow of fresh air throughout the home. The skylight over
              the courtyard lets in ambient sunlight while additional light
              filters in through the glass blocks on the eastern side. This
              natural illumination bathes the interior in a warm, inviting glow.
              The striking steel and wood staircase rivals any art installation
              while still providing vertical circulation.
            </p>
            <img style={{ width: "100%" }} src={Image22} />
            <p style={{ textAlign: "justify" }}>
              Perforated steel screens are strategically used in several key
              areas to control lighting. In the bedroom, these screens are
              installed on the western windows to filter the harsh rays of the
              setting sun. Such usage reduces heat and adds a dynamic visual
              element to the space while maintaining privacy.
              <br />
              <br />
              Roofing comprises stratified layers in natural tones to ground the
              building to its surroundings. The sandwich panel roofing with its
              exposed concrete ceiling is evident over the central courtyard as
              it creates a visual contrast against the greenery below. <br />
              <br />
              Polished concrete flooring runs throughout the house from the
              living room to the bedrooms. Exposed structural elements such as
              steel beams provide an industrial edge, whereas natural wooden
              textures soften the overall aesthetic.
              <br />
              <img style={{ width: "100%" }} src={Image9} />
              <br />
              The exterior has a combination of matte and glossy concrete
              finishes juxtaposing the glass and wooden elements to give the
              facade a modern yet organic feel. The matte concrete is used on
              the subdued exterior walls while the glossy concrete highlights
              key features such as the sleek frames around large windows.
              Expansive glass panels and floor-to-ceiling windows allow natural
              light to flood in while offering stunning views of the courtyard
              and landscapes. The main entrance door and other wooden elements
              introduce warmth and organic touch to the minimalist exterior.
              <br />
              <img style={{ width: "100%" }} src={Image10} />
            </p>
            <div className="mobile-div23">Conclusion</div>
            <p style={{ textAlign: "justify" }}>
              Celandine Residence is a seamless integration of modern design
              ethos with the tropical essence of Kerala. The outcome is a serene
              living experience that not only meets the functional needs of the
              occupants but also fosters a deep connection with nature. By
              honoring tradition while embracing innovation this abode is a
              sanctuary and a statement of contemporary tropical architecture.
            </p>
          </div>
        </div>
        <img style={{ width: "100%" }} className="image12" src={Image12} />
      </div>
      <Footer />
    </div>
  );
};

Desktop.propTypes = {
  className: PropTypes.string,
};

export default Desktop;
