import React from "react";
import Image from "../../Assets/COMPRESSED/a/Mask group (5).png";
import Image1 from "../../Assets/COMPRESSED/a/Mask group (6).png";
import Image3 from "../../Assets/COMPRESSED/a/Mask group (7).png";
import Image4 from "../../Assets/COMPRESSED/a/Mask group (10).png";
import "./style.css";
import { useNavigate } from "react-router-dom";

const PropertyCard = ({ image, title, description, className, navigate }) => (
  <div className={`property-card ${className}`}>
    <img src={image} alt={title} />
    <div className="card-title">{title}</div>
    <div className="property-info">
      <h3 className="property-title">{title}</h3>
      <p className="property-description">{description}</p>
      <button
        className="explore-btn"
        onClick={() => {
          navigate("/desktop");
        }}
      >
        Explore &gt;&gt;
      </button>
    </div>
  </div>
);

const PropertyGallery = () => {
  const navigate = useNavigate();

  const properties = [
    {
      image: Image,
      title: "Celandine",
      description:
        "This sunlit 'breathing' house ensures that its occupants are immersed in Nature; engaging with her whims, her sunny warmth and playful rains.",
      className: "large",
    },
    {
      image: Image1,
      title: "Bedrooms",
      description:
        "The private bedrooms are designed with personal balconies or bay windows so that each family member may enjoy the outdoors from the shade and at their own pace.",
      className: "small",
    },
    {
      image: Image3,
      title: "Living Spaces",
      description:
        "The Gazebo is designed as an open landscape element with a steel structure, which can be accessed through the living room courtyard.",
      className: "small",
    },
    {
      image: Image4,
      title: "Outdoor Living",
      description:
        "This sunlit 'breathing' house ensures that its occupants are immersed in Nature; engaging with her whims, her sunny warmth and playful rains.",
      className: "wide",
    },
  ];

  return (
    <div className="property-gallery">
      {properties.map((property, index) => (
        <PropertyCard key={index} {...property} navigate={navigate} />
      ))}
    </div>
  );
};

export default PropertyGallery;
