import React, { useState } from "react";
import "./style.css"; // Ensure this points to the updated CSS file
import logo from "../Assets/Group 25.png";
import logo1 from "../Assets/Group (1).png";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const navigate = useNavigate();
  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <header className="header">
      <div className="logo">
        <img className="logo-img" src={logo} alt="Home Icon" />
        {/* <img style={{ width: "100%" }} src={logo1} alt="Logo" /> */}
        <div className="menu-icon" onClick={toggleNav}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <nav className={`nav ${isNavVisible ? "active" : ""}`}>
        <a
          href="#"
          className="nav-item"
          onClick={() => {
            toggleNav();
            navigate("/");
          }}
        >
          Home
        </a>
        <a href="#" className="nav-item" onClick={toggleNav}>
          Corporate
        </a>
        <a href="#" className="nav-item" onClick={toggleNav}>
          Products
        </a>
        <a href="#" className="nav-item" onClick={toggleNav}>
          Get Inspired
        </a>
      </nav>
    </header>
  );
};

export default Header;
