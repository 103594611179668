import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HTMLFlipBook from "react-pageflip";
import { jsPDF } from "jspdf";
import {
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
  FaShareAlt,
} from "react-icons/fa";
import "./style.css";

// Import all 32 images
import Image1 from "../../Assets/COMPRESSED/1.jpg";
import Image2 from "../../Assets/COMPRESSED/2.jpg";
import Image3 from "../../Assets/COMPRESSED/3.jpg";
import Image4 from "../../Assets/COMPRESSED/4.jpg";
import Image5 from "../../Assets/COMPRESSED/5.jpg";
import Image6 from "../../Assets/COMPRESSED/6.jpg";
import Image7 from "../../Assets/COMPRESSED/7.jpg";
import Image8 from "../../Assets/COMPRESSED/8.jpg";
import Image9 from "../../Assets/COMPRESSED/9.jpg";
import Image10 from "../../Assets/COMPRESSED/10.jpg";
import Image11 from "../../Assets/COMPRESSED/11.jpg";
import Image12 from "../../Assets/COMPRESSED/12.jpg";
import Image13 from "../../Assets/COMPRESSED/13.jpg";
import Image14 from "../../Assets/COMPRESSED/14.jpg";
import Image15 from "../../Assets/COMPRESSED/15.jpg";
import Image16 from "../../Assets/COMPRESSED/16.jpg";
import Image17 from "../../Assets/COMPRESSED/17.jpg";
import Image18 from "../../Assets/COMPRESSED/18.jpg";
import Image19 from "../../Assets/COMPRESSED/19.jpg";
import Image20 from "../../Assets/COMPRESSED/20.jpg";
import Image21 from "../../Assets/COMPRESSED/21.jpg";
import Image22 from "../../Assets/COMPRESSED/22.jpg";
import Image23 from "../../Assets/COMPRESSED/23.jpg";
import Image24 from "../../Assets/COMPRESSED/24.jpg";
import Image25 from "../../Assets/COMPRESSED/25.jpg";
import Image26 from "../../Assets/COMPRESSED/26.jpg";
import Image27 from "../../Assets/COMPRESSED/27.jpg";
import Image28 from "../../Assets/COMPRESSED/28.jpg";
import Image29 from "../../Assets/COMPRESSED/29.jpg";
import Image30 from "../../Assets/COMPRESSED/30.jpg";
import Image31 from "../../Assets/COMPRESSED/31.jpg";
import Image32 from "../../Assets/COMPRESSED/32.jpg";
import Image34 from "../../Assets/COMPRESSED/New Project.jpg";
import Image35 from "../../Assets/COMPRESSED/New Project.jpg";
import logo from "../../Assets/Group 25.png";
import { Download } from "lucide-react";

const SkeletonLoader = () => (
  <div className="skeleton-loader">
    <div className="skeleton-page"></div>
    <div className="skeleton-page"></div>
  </div>
);

const Magazine = () => {
  const location = useLocation();
  const [isNavVisible, setIsNavVisible] = useState(false);
  const navigate = useNavigate();
  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };
  const bookRef = useRef(null);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [shareUrl, setShareUrl] = useState("");

  const isMobile = windowDimensions.width <= 768;

  const allPages = [
    { image: Image34 },
    { image: Image1 },
    { image: Image2 },
    { image: Image3 },
    { image: Image4 },
    { image: Image5 },
    { image: Image6 },
    { image: Image7 },
    { image: Image8 },
    { image: Image9 },
    { image: Image10 },
    { image: Image11 },
    { image: Image12 },
    { image: Image13 },
    { image: Image14 },
    { image: Image15 },
    { image: Image16 },
    { image: Image17 },
    { image: Image18 },
    { image: Image19 },
    { image: Image20 },
    { image: Image21 },
    { image: Image22 },
    { image: Image23 },
    { image: Image24 },
    { image: Image25 },
    { image: Image26 },
    { image: Image27 },
    { image: Image28 },
    { image: Image29 },
    { image: Image30 },
    { image: Image31 },
    { image: Image32 },
    { image: Image35 },
  ];

  const displayPages = isMobile ? allPages.slice(1, -1) : allPages;
  const pdfPages = allPages.slice(1, -1); // Remove first and last pages for PDF

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let loadedImages = 0;
    const totalImages = displayPages.length;

    const imagePromises = displayPages.map((page) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          loadedImages++;
          if (loadedImages === totalImages) {
            setImagesLoaded(true);
          }
          resolve();
        };
        img.onerror = reject;
        img.src = page.image;
      });
    });

    Promise.all(imagePromises).then(() => setImagesLoaded(true));
  }, [displayPages]);

  const generatePDF = async () => {
    setIsGeneratingPDF(true);
    const pdf = new jsPDF();

    for (let i = 0; i < pdfPages.length; i++) {
      if (i > 0) pdf.addPage();

      await new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          const imgProps = pdf.getImageProperties(img);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
          resolve();
        };
        img.src = pdfPages[i].image;
      });
    }

    pdf.save("Celandine_The Sunlight Bloom.pdf");
    setIsGeneratingPDF(false);
  };

  const shareToWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?text=Homeemag Issue 01- Celandine: The Sunlight Bloom: ${encodeURIComponent(
      shareUrl
    )}`;
    window.open(url, "_blank");
  };

  const shareToFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(url, "_blank");
  };

  const shareToInstagram = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert(
        "Link copied! You can now paste this in your Instagram story or post."
      );
    });
  };

  const shareGeneral = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Celandine: The Sunlight Bloom",
          text: "Check out this amazing magazine!",
          url: shareUrl,
        })
        .catch((error) => console.log("Error sharing", error));
    } else {
      navigator.clipboard.writeText(shareUrl).then(() => {
        alert("Link copied to clipboard!");
      });
    }
  };

  if (!imagesLoaded) {
    return <SkeletonLoader />;
  }

  return (
    <div>
      <header className="headers">
        <div className="logo">
          <img className="logo-img" src={logo} alt="Home Icon" />
          {/* <img style={{ width: "100%" }} src={logo1} alt="Logo" /> */}
          <div className="menu-icon" onClick={toggleNav}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <nav className={`nav ${isNavVisible ? "active" : ""}`}>
          <a
            href="#"
            className="nav-item"
            onClick={() => {
              toggleNav();
              navigate("/");
            }}
          >
            Home
          </a>
          <a href="#" className="nav-item" onClick={toggleNav}>
            Corporate
          </a>
          <a href="#" className="nav-item" onClick={toggleNav}>
            Products
          </a>
          <a href="#" className="nav-item" onClick={toggleNav}>
            Get Inspired
          </a>
        </nav>
      </header>

      <div className="magazine-container">
        <HTMLFlipBook
          width={isMobile ? 300 : 400}
          height={isMobile ? 450 : 600}
          size="stretch"
          minWidth={300}
          maxWidth={1000}
          minHeight={400}
          maxHeight={1350}
          maxShadowOpacity={0.5}
          mobileScrollSupport={true}
          ref={bookRef}
          className="magazine"
        >
          {displayPages.map((page, index) => (
            <div key={index} className="page">
              <div className="page-content">
                <img
                  src={page.image}
                  alt={`Page ${index + 1}`}
                  className="page-image"
                />
              </div>
            </div>
          ))}
        </HTMLFlipBook>
        <div className="share-buttons">
          <button onClick={shareToWhatsApp} className="share-button whatsapp">
            <FaWhatsapp />
          </button>
          <button onClick={shareToFacebook} className="share-button facebook">
            <FaFacebook />
          </button>
          <button onClick={shareToInstagram} className="share-button instagram">
            <FaInstagram />
          </button>
          <button onClick={shareGeneral} className="share-button general">
            <FaShareAlt />
          </button>
        </div>

        <div
          onClick={generatePDF}
          className="download-button"
          disabled={isGeneratingPDF}
        >
          {isGeneratingPDF ? "Generating PDF..." : "DOWNLOAD PDF"} <Download />
        </div>
        <h1 style={{ marginTop: 2 }}>Celandine</h1>
      </div>
    </div>
  );
};

export default Magazine;
