import React, { useState, useRef, useEffect } from "react";
import { BookOpen, Menu, Play } from "lucide-react";
import "./style.css";
import logo from "../../Assets/Group.png";
import logo1 from "../../Assets/Group (1).png";
import logo3 from "../../Assets/Mask group (1).png";
import logo4 from "../../Assets/Mask group (2).png";
import videoFile from "../../Assets/Clentaine/FOR WEB.mp4"; // Updated video import

import PropertyGallery from "../../Components/Card/PropertyGallery";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useNavigate();
  const videoRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <div className="home-container">
        <div className="content-container">
          <nav className="navbar">
            <div className="menu-icon" onClick={toggleMenu}>
              <Menu size={24} color="white" />
            </div>
            <div className="logo">
              <img style={{ width: "100%" }} src={logo} alt="Home Icon" />
              <img style={{ width: "100%" }} src={logo1} alt="Logo" />
            </div>
            <ul className={`nav-links ${isMenuOpen ? "active" : ""}`}>
              <li
                onClick={() => {
                  history("/");
                }}
              >
                Home
              </li>
              <li>Corporate</li>
              <li>Products</li>
              <li>Get Inspired</li>
            </ul>
          </nav>
          <div className="main-content">
            <div className="head">Celandine</div>
            <div className="para">
              Welcome to our natural home tour! Here, we invite you to step into
              a space where harmony with nature is at the heart of every design
              choice.
            </div>
            <div
              className="explore-btn-main"
              onClick={() => {
                history("/desktop");
              }}
            >
              Explore &gt;&gt;
            </div>
            <div className="image-thumbnails">
              <img src={logo3} alt="Thumbnail 1" />
              <img src={logo4} alt="Thumbnail 2" />
            </div>
          </div>
        </div>
        <div className="image-container">
          <video
            ref={videoRef}
            className="main-video"
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
          >
            <source src={videoFile} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button
            className="watch-video-btn"
            onClick={() =>
              window.open(
                "https://www.youtube.com/watch?v=PsTpnmwGE14",
                "_blank"
              )
            }
          >
            <Play size={20} /> Watch Video
          </button>
          <button
            onClick={() => {
              history("/Celandine-The-Sunlit-Bloom");
            }}
            className="e-magazine-btn"
          >
            <BookOpen size={20} /> E-Magazine
          </button>
        </div>
      </div>
      <div className="home-main-div">
        <PropertyGallery />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
