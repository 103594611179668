import PropTypes from "prop-types";
import "./style.css";
import Image from "../../Assets/Clentaine/271568_0_20240823125550 copy 2 1.png";
import Image1 from "../../Assets/Clentaine/WEB IMG (1).png";
import Image2 from "../../Assets/Clentaine/Group 6 1 (2).png";
import Image3 from "../../Assets/Clentaine/IMG_0513 1 (1).png";

const FrameComponent2 = ({ className = "" }) => {
  return (
    <div className={`frame-wrapper ${className}`}>
      <div className="frame-parent2">
        <div className="frame-parent3">
          <div className="frame-wrapper1">
            <div className="rectangle-parent3">
              <div className="frame-child3" />
              <h1 className="site-context">Site Context</h1>
              <div className="the-15-cent-site-in-muringoor-wrapper">
                <div className="the-15-cent-site">
                  The 15-cent site in Muringoor, Thrissur, with a busy national
                  highway just 20 yards away, directed the focus on creating a
                  tranquil urban retreat. An inward-facing orientation
                  highlights the lush green courtyard, serving as the nucleus of
                  the 3500 sq ft house. Kerala’s tropical climate, with its
                  heavy monsoons, verdant terrains, and hot, humid summers,
                  demands architecture that is adaptable and attuned to this
                  context.
                </div>
              </div>
              <img
                className="img-0484-1-icon"
                loading="lazy"
                alt=""
                src={Image1}
              />
              <img
                className="img-0484-2-icon"
                loading="lazy"
                alt=""
                src={Image}
              />
            </div>
          </div>
          <div className="rectangle-parent4">
            <div className="frame-child4" />
            <div className="principal-architect-arshyam-container">
              <p className="principal-architect">
                Principal Architect: Ar.Shyam Raj.C
              </p>
              <p className="project-associate-aramal">
                Project Associate: Ar.Amal Raj.C
              </p>
              <p className="project-associate-aramal">
                location details: Muringoor, Thrissur
              </p>
              <p className="project-associate-aramal">plot area: 15 cents</p>
              <p className="project-associate-aramal">
                project sq ft: 3500 sqft
              </p>
              <p className="project-associate-aramal">{`Client Name Details: `}</p>
              <p className="project-associate-aramal">{`Mr.Jickson & Mrs.Jisha`}</p>
              <p className="project-associate-aramal">Firm name:</p>
              <p className="project-associate-aramal">
                7th HUE ARCHITECTURE COLLECTIVE
              </p>
            </div>
          </div>
        </div>
        <div className="a">
          <div>
            <img className="img-1" style={{ left: 20 }} src={Image2} />
          </div>
          <div className="rectangle-parent5">
            <div>
              <div className="frame-child5" />
              <div className="dubai-family">
                <h1 className="planning">Planning</h1>
              </div>
              <div className="dubai-family1">
                <div className="the-clients-a">
                  The clients, a family of four returning from Dubai, sought a
                  design that would blend their memories of Kerala with the
                  modern amenities they were accustomed to. Merging the parent's
                  dream of an idyllic tropical bliss with the children's need
                  for contemporary spaces was the challenge
                </div>
              </div>
            </div>
            <div className="b">
              <img className="img-1" src={Image3} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
