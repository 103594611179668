import React from "react";
import { Facebook, Linkedin, Instagram, Phone } from "lucide-react";
import { BsWhatsapp, BsYoutube } from "react-icons/bs";
import Logo from "../Assets/Group 21.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <h2 className="footer-head">Company</h2>
        <ul className="footer-list">
          <li>Home</li>
          <li>Corporate</li>
          <li>Products</li>
          <li>Get Inspired</li>
        </ul>
      </div>

      <div className="footer-section">
        <h2 className="footer-head">Contact Us</h2>
        <div className="logo">
          <img src={Logo} alt="Company Logo" className="logo-image" />
        </div>
        <p className="address">
          NH 544, First Floor, G.I Square,
          <br />
          Above Lenskart,
          <br />
          Kothakulangara, Angamaly
          <br />
          Kerala, India
          <br />
          683 572
        </p>
        <p className="contact-info">
          Contact No: <a href="tel:+918075896805">+91 8075896805</a>
        </p>

        <p className="contact-info">
          Inbox: <a href="mailto:info@homeemag.com">info@homeemag.com</a>
        </p>
      </div>

      <div className="footer-section">
        <h2 className="footer-head">Social Media</h2>
        <div className="social-icons">
          <a
            href="https://facebook.com/share/tH7qBtCjRdem5Xw4/?mibextid=qi2Omg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook size={24} />
          </a>
          <a
            href="https://linkedin.com/company/homeemag"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linkedin size={24} />
          </a>
          <a
            href="https://instagram.com/homeemag.india?igsh=bHpyMjMzam55bGRk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram size={24} />
          </a>
          <a
            href="https://wa.me/918075896805"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsWhatsapp size={24} />
          </a>
          <a
            href="https://youtube.com/@homeemag?si=womy7n-r8F_gcch3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsYoutube size={24} />
          </a>
        </div>
      </div>

      <style jsx>{`
        .footer {
          display: flex;
          justify-content: space-between;
          background-color: #000;
          color: #fff;
          padding: 3rem;
          font-family: Arial, sans-serif;
        }

        .footer-section {
          flex: 1;
          margin: 0 1rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .footer-head {
          font-size: 1.5rem;
          margin-bottom: 1.5rem;
          font-weight: bold;
        }

        .footer-list {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }

        .footer-list li {
          margin-bottom: 0.75rem;
          font-size: 1rem;
        }

        .logo-image {
          max-width: 150px;
        }

        .address,
        .contact-info {
          font-size: 0.9rem;
          line-height: 1.5;
          margin-bottom: 0.5rem;
          text-align: left;
        }

        .social-icons {
          display: flex;
          gap: 1rem;
          margin-top: 1rem;
        }

        .social-icons a {
          color: #fff;
          transition: color 0.3s ease;
        }

        .social-icons a:hover {
          color: #ddd;
        }

        @media (max-width: 768px) {
          .footer {
            flex-direction: column;
            padding: 2rem 1rem;
          }

          .footer-section {
            margin: 0 0 2rem 0;
            align-items: center;
            width: 100%;
          }

          .footer-head {
            font-size: 1.3rem;
            margin-bottom: 1rem;
            text-align: center;
          }

          .footer-list {
            text-align: center;
            width: 100%;
          }

          .footer-list li {
            margin-bottom: 0.5rem;
            font-size: 0.9rem;
          }

          .logo-image {
            max-width: 120px;
          }

          .address,
          .contact-info {
            text-align: center;
            font-size: 0.8rem;
          }

          .social-icons {
            justify-content: center;
            flex-wrap: wrap;
          }

          .social-icons a {
            margin: 0.5rem;
          }
        }

        @media (max-width: 480px) {
          .footer {
            padding: 1.5rem 0.5rem;
          }

          .footer-head {
            font-size: 1.2rem;
          }

          .footer-list li,
          .address,
          .contact-info {
            font-size: 0.75rem;
          }

          .logo-image {
            max-width: 100px;
          }

          .social-icons svg {
            width: 20px;
            height: 20px;
          }
        }
      `}</style>
    </footer>
  );
};

export default Footer;
