import PropTypes from "prop-types";
import "./style.css";
import Image from "../../Assets/Clentaine/IMG_0477 1.png";

const FrameComponent1 = ({ className = "" }) => {
  return (
    <div className={`frame-parent1 ${className}`}>
      <div className="mask-group-parent">
        <img className="mask-group-icon" loading="lazy" alt="" src={Image} />
        <div className="embracing-nature-and">
          - Embracing Nature and Sunlight in a Tropical Residence
        </div>
      </div>
      <div className="rectangle-parent2">
        <div className="frame-child2" />
        <h1 className="celendine">Celendine</h1>
        <div className="this-sunlit-breathing">
          This sunlit 'breathing' house ensures that its occupants are immersed
          in Nature, engaging with her changing moods, her sunny warmth and
          playful rains. Amal Raj Chandroth and Shyam Raj Chandroth from 7th Hue
          Architecture Studio blend the tropical essence of Kerala Architecture
          with modern comforts to design this contemporary residence. Celandine
          House reflects the vibrant spirit of the yellow flower, found in
          abundance on the site, which holds nostalgic meaning for the clients.
          Just as the blooming celandine flower lights up the earth, this abode
          invites sunlight to light up its living spaces.
        </div>
      </div>
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
